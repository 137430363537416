/* global PUBLIC_PATH */
import moment from 'moment';
import 'moment-timezone';

export default () => {
    const daytimeTextElement = document.querySelector('.js-daytime-text');
    const emojiElement = document.querySelector('.js-daytime-emoji');
    const currentTimeInHours = parseFloat(
        moment(new Date().toISOString())
            .tz('Europe/Moscow')
            .format('H'),
    );

    const getDaytime = (hours) => {
        switch (true) {
            case hours >= 7 && hours < 10:
                return 'Утро';
            case hours >= 10 && hours < 19:
                return 'День';
            case hours >= 19 && hours <= 22:
                return 'Вечер';
            default:
                return 'Ночь';
        }
    };

    const getEmoji = (daytime) => {
        switch (daytime.toLowerCase()) {
            case 'утро':
                return `${PUBLIC_PATH}img/coffee.png`;
            case 'день':
                return `${PUBLIC_PATH}img/work.png`;
            case 'вечер':
                return `${PUBLIC_PATH}img/party.png`;
            default:
                return `${PUBLIC_PATH}img/sleep.png`;
        }
    };

    const getText = (daytime) => {
        switch (daytime.toLowerCase()) {
            case 'утро':
                return 'У нас утро, мы просыпаемся';
            case 'день':
                return 'У нас день и мы работаем';
            case 'вечер':
                return 'У нас вечер и мы на тусовке';
            default:
                return 'У нас ночь и мы спим';
        }
    };

    const daytime = getDaytime(currentTimeInHours);
    const text = getText(daytime);
    const emoji = getEmoji(daytime);

    if (daytimeTextElement) {
        daytimeTextElement.textContent = text;
    }

    if (emojiElement) {
        emojiElement.src = emoji;
    }
};
