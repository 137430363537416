/* global PUBLIC_PATH */
import debounce from 'lodash.debounce';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { TweenLite, Power1 } from 'gsap/all';

import THREE from '../../three';
import { pageState, dragging } from './plugins';
import { triggerCustomEvent } from '../../utils';

const getMountPos = () => {
    switch (true) {
        case window.matchMedia('((min-width: 901px) and (max-width: 1024px))').matches:
            return { x: 0, y: 0, z: -150 };
        case window.matchMedia('((min-width: 768px) and (max-width: 900px))').matches:
            return { x: 0, y: 0, z: -250 };
        case window.matchMedia('(max-width: 767px)').matches:
            return { x: -30, y: -130, z: -450 };
        default:
            return { x: 0, y: 0, z: 0 };
    }
};

const getMeshOffset = (name) => {
    switch (name) {
        case 'about':
            return { x: 53, y: -85, z: -94 };
        case 'services':
            return { x: 95, y: -5, z: -5 };
        case 'cases':
            return { x: -82.5, y: -47.5, z: 18.7 };
        case 'ordering':
            return { x: -53.7, y: 14.5, z: 60.5 };
        default:
            return { x: 0, y: 0, z: 0 };
    }
};

function getAndroidVersion() {
    const ua = navigator.userAgent.toLowerCase();
    const match = ua.match(/android\s([0-9.]*)/i);
    return match ? match[1] : undefined;
}

// getAndroidVersion(); //"4.2.1"
// parseInt(getAndroidVersion(), 10);

export const isWebGlAvailable = () => {
    try {
        const canvas = document.createElement('canvas');
        return !!(
            window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
};

const defaultOptions = {
    onReady: () => {},
    onError: () => {},
};

export default class Mount {
    constructor(container, options = {}) {
        const dpr = window.devicePixelRatio;
        this.container = container;
        this.options = { ...defaultOptions, ...options };

        if (!isWebGlAvailable()) {
            document.documentElement.classList.add('no-webgl');
            this.trigger('error');
            throw new Error('[Mount] WebGL is not supported.');
        }

        this.plugins = [pageState, dragging];
        this.isDocumentHidden = false;
        this.shouldAnimate = true;
        this.linkOffset = window.matchMedia('(max-width: 1024px)').matches ? 15 : 30;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.tempV = new THREE.Vector3();
        this.modelGroup = new THREE.Group();
        this.group = new THREE.Group();

        // Renderer
        this.renderer = new THREE.WebGLRenderer({ alpha: false, antialias: true });
        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(dpr);
        this.renderer.gammaOutput = true;
        this.renderer.gammaFactor = 2.3;
        this.container.appendChild(this.renderer.domElement);

        // Scene
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x121525);

        // Camera
        this.camera = new THREE.PerspectiveCamera(35, this.width / this.height, 1, 1000);
        this.camera.position.set(0, 0, 450);

        // Lights
        this.light = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(this.light);

        this.directionalLight = new THREE.DirectionalLight(0x121525, 2);
        this.directionalLight.position.set(177, 67, 380);
        this.directionalLight.target = this.group;
        this.scene.add(this.directionalLight);

        // Curves
        const aboutServicesCurve = this.createCurve(
            new THREE.CubicBezierCurve3(
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(0, 50, 0),
                new THREE.Vector3(0, 30, -50),
                new THREE.Vector3(-86, 90, -29),
            ),
            { x: 22.5, y: -95, z: 99.5 },
        );

        const servicesCasesCurve = this.createCurve(
            new THREE.CubicBezierCurve3(
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(0, 20, -30),
                new THREE.Vector3(-30, 20, -40),
                new THREE.Vector3(155, 25, -95),
            ),
            { x: -63.5, y: -6, z: 70.5 },
        );

        const casesOrderingCurve = this.createCurve(
            new THREE.CubicBezierCurve3(
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(-20, 30, 0),
                new THREE.Vector3(-70, 10, -10),
                new THREE.Vector3(-65, 42, -24),
            ),
            { x: 92.5, y: 20, z: -24.5 },
        );

        this.paths = [
            {
                name: 'about',
                mesh: null,
                isAnimating: false,
                active: true,
            },
            {
                name: 'services',
                mesh: aboutServicesCurve,
                isAnimating: false,
                active: false,
            },
            {
                name: 'cases',
                mesh: servicesCasesCurve,
                isAnimating: false,
                active: false,
            },
            {
                name: 'ordering',
                mesh: casesOrderingCurve,
                isAnimating: false,
                active: false,
            },
        ];

        this.deferredPath = null;

        // Particles
        this.createParticles();

        // Loader
        try {
            const androidVersion = getAndroidVersion();
            if (androidVersion && parseInt(androidVersion, 10) === 5) {
                throw new Error('Android 5 detected, switching to the static version...');
            }

            this.loader = new THREE.GLTFLoader();

            if (THREE.DRACOLoader) {
                const dracoLoader = new THREE.DRACOLoader();
                dracoLoader.setDecoderPath(`${PUBLIC_PATH}draco/`);
                this.loader.setDRACOLoader(dracoLoader);
            }

            this.animate = this.animate.bind(this);
            this.onVisibilityChange = this.onVisibilityChange.bind(this);
            this.onResize = this.onResize.bind(this);

            this.debouncedOnResize = debounce(this.onResize, 20);

            this.init();
            this.start();
        } catch (err) {
            console.error(err);
            console.log('Switching to the static version...');
            document.documentElement.classList.add('no-webgl');
            this.trigger('error');
            this.render();
            const img = new Image();
            img.className = 'mount-static';
            img.src = `${PUBLIC_PATH}img/mount.png`;
            img.alt = '';
            this.container.appendChild(img);
            this.plugins = this.plugins.map((plugin) => plugin(this));
            this.options.onError();
        }
    }

    on(eventName, fn) {
        this.container.addEventListener(eventName, fn);
    }

    trigger(eventName) {
        triggerCustomEvent(this.container, eventName);
    }

    init() {
        this.loader.load(
            `${PUBLIC_PATH}gltf/mount-draco.glb`,
            (gltf) => {
                const { scene } = gltf;
                const mount = scene.children.find((obj) => obj.name === 'Landscape_matte');
                const wire = scene.children.find((obj) => obj.name === 'Landscape_wire');
                const aboutPoint = scene.children.find((obj) => obj.name === 'Sphere');
                const servicesPoint = scene.children.find((obj) => obj.name === 'Sphere1');
                const casesPoint = scene.children.find((obj) => obj.name === 'Sphere2');
                const orderingPoint = scene.children.find((obj) => obj.name === 'Sphere3');
                const pointMaterial = new THREE.MeshBasicMaterial({ color: 0xffd23f });
                const wireMaterial = new THREE.MeshBasicMaterial({ color: 0x26a9cb });
                const linkElements = Array.from(document.querySelectorAll('[data-mount-link]'));

                const getMesh = (name) => {
                    switch (name) {
                        case 'about':
                            return aboutPoint;
                        case 'services':
                            return servicesPoint;
                        case 'cases':
                            return casesPoint;
                        case 'ordering':
                            return orderingPoint;
                        default:
                            return null;
                    }
                };

                scene.rotation.y = Math.PI / 4;
                mount.material.color = new THREE.Color(0x121525);

                wire.children.forEach((mesh) => {
                    // mesh.material.dispose();
                    mesh.material = wireMaterial;
                });

                this.links = linkElements.map((el) => ({
                    htmlElement: el,
                    mesh: getMesh(el.dataset.mountLink),
                    offset: getMeshOffset(el.dataset.mountLink),
                }));

                this.links.forEach(({ htmlElement, mesh, offset }) => {
                    // Set different point material
                    // mesh.material.dispose();
                    mesh.material = pointMaterial;
                    // Set origin to [0, 0, 0]
                    if (mesh.geometry) {
                        mesh.geometry.applyMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
                    }
                    mesh.position.set(-offset.x, offset.z, -offset.y);

                    htmlElement.addEventListener('mouseenter', () => {
                        if (document.body.classList.contains('modal-page-opened')) {
                            return;
                        }
                        const nextPageName = htmlElement.dataset.mountLink;
                        this.animateCurve(nextPageName);
                    });

                    htmlElement.addEventListener('mouseleave', () => {
                        if (document.body.classList.contains('modal-page-opened')) {
                            return;
                        }

                        this.animateCurve('about');
                    });
                });

                this.model = scene;
                this.modelGroup.add(this.model);

                const mountPos = getMountPos();
                this.modelGroup.position.set(mountPos.x, mountPos.y, mountPos.z);

                this.group.add(this.modelGroup);
                this.scene.add(this.group);

                document.addEventListener('visibilitychange', this.onVisibilityChange);
                window.addEventListener('resize', this.debouncedOnResize);
                window.addEventListener('orientationchange', this.debouncedOnResize);

                this.plugins = this.plugins.map((plugin) => plugin(this));

                this.options.onReady();
                this.onResize();

                // this.setupGUI();
            },
            null,
            (err) => {
                this.options.onError();
                throw err;
            },
        );
    }

    destroy() {
        this.plugins.forEach((destroyFn) => destroyFn());
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
        window.removeEventListener('resize', this.debouncedOnResize);
        window.removeEventListener('orientationchange', this.debouncedOnResize);
        this.model.children.forEach((mesh) => {
            mesh.material.dispose();
        });
        this.model = null;
        this.renderer = null;
        this.camera = null;
        this.scene = null;
        this.light = null;
        this.directionalLight = null;
    }

    createParticles() {
        const spherical = new THREE.Spherical();
        const starDistance = 500;
        const startsCount = 150;
        const minSize = 1;
        const maxSize = 3;

        this.itemCanvas = document.createElement('canvas');
        this.itemCanvas.width = 32;
        this.itemCanvas.height = 32;

        this.renderStars();

        this.itemMaterial = new THREE.SpriteMaterial({
            map: new THREE.CanvasTexture(this.itemCanvas),
            blending: THREE.AdditiveBlending,
        });

        this.starsGroup = new THREE.Group();

        for (let i = 0; i < startsCount; i += 1) {
            const particle = new THREE.Sprite(this.itemMaterial);
            // Disable rendering if outside the camera view
            particle.frustumCulled = false;
            const distance = starDistance + THREE.Math.randFloat(-2, 2);
            const phi = Math.acos(-1 + (2 * i) / startsCount) + THREE.Math.randFloat(-0.01, 0.01);
            const theta = Math.sqrt(startsCount * Math.PI) * phi + THREE.Math.randFloat(-0.01, 0.01);

            spherical.set(distance, phi, theta);
            particle.position.setFromSpherical(spherical);
            particle.scale.x = THREE.Math.randFloat(minSize, maxSize);
            particle.scale.y = particle.scale.x;

            this.starsGroup.add(particle);
        }

        this.group.add(this.starsGroup);
    }

    renderStars() {
        const context = this.itemCanvas.getContext('2d');
        const halfWidth = this.itemCanvas.width / 2;
        const halfHeight = this.itemCanvas.height / 2;

        const gradient = context.createRadialGradient(halfWidth, halfHeight, 0, halfWidth, halfHeight, halfWidth);
        gradient.addColorStop(0.0, 'rgba(255, 255, 255, 1.0)');
        gradient.addColorStop(0.5, 'rgba(210, 223, 255, 1.0)');
        gradient.addColorStop(0.7, 'rgba(68, 99, 255, 0.8)');
        gradient.addColorStop(1, 'rgba(68, 99, 255, 0.0)');

        context.fillStyle = gradient;
        context.clearRect(0, 0, this.itemCanvas.width, this.itemCanvas.height);
        context.fillRect(0, 0, this.itemCanvas.width, this.itemCanvas.height);
    }

    createCurve(bezierCurveInstance, origin) {
        this.curveMaterial = new MeshLineMaterial({
            color: new THREE.Color(0xffd23f),
            lineWidth: window.matchMedia('(max-width: 767px)').matches ? 5 : 10,
            resolution: new THREE.Vector2(this.width, this.height),
            near: this.camera.near,
            far: this.camera.far,
            dashArray: 5,
            dashOffset: 0,
            dashRatio: 0.5,
            sizeAttenuation: false,
            transparent: true,
            opacity: 1,
        });

        // MeshLine doesn't support BufferGeometry
        const geometry = new THREE.Geometry().setFromPoints(bezierCurveInstance.getPoints(50));
        const meshLine = new MeshLine();
        meshLine.setGeometry(geometry);
        const mesh = new THREE.Mesh(meshLine.geometry, this.curveMaterial);

        mesh.position.set(origin.x, origin.y, origin.z);
        this.modelGroup.add(mesh);

        return mesh;
    }

    getPathByPageName(name) {
        return this.paths.find((path) => path.name === name);
    }

    _animateCurve(curve, value = -1.2) {
        return new Promise((resolve) => {
            if (!curve) {
                resolve();
                return;
            }
            TweenLite.to(curve.material.uniforms.dashOffset, 0.5, {
                value,
                onUpdate: () => {
                    this.shouldAnimate = true;
                },
                onComplete: () => {
                    resolve();
                    this.shouldAnimate = false;
                },
            });
        });
    }

    async showCurve(curve) {
        return this._animateCurve(curve, -1.2);
    }

    async hideCurve(curve) {
        return this._animateCurve(curve, 0);
    }

    // TODO: Надо тут порефакторить
    async animateCurve(toPageName, force = false) {
        return new Promise(async (resolve, reject) => {
            const animatingPath = this.paths.find((path) => !!path.isAnimating);
            const activePath = force
                ? this.getPathByPageName(toPageName === 'main' ? 'about' : toPageName)
                : this.paths.find((path) => !!path.active);

            if (!force && animatingPath) {
                this.deferredPathPageName = toPageName;
                reject(new Error('is currently animating'));
                return;
            }

            // debugger;
            const startPathIndex = Math.max(0, this.paths.indexOf(activePath));
            const endPathIndex = Math.max(
                0,
                this.paths.indexOf(this.getPathByPageName(toPageName === 'main' ? 'about' : toPageName)),
            );

            if (startPathIndex !== endPathIndex) {
                if (this.timeouts) {
                    this.timeouts.forEach((id) => clearTimeout(id));
                }

                this.timeouts = [];

                if (startPathIndex < endPathIndex) {
                    // Show curves
                    for (let i = 0; i < endPathIndex - startPathIndex; i++) {
                        const id = setTimeout(() => {
                            if (this.deferredPathPageName) {
                                this.animateCurve(this.deferredPathPageName);
                                this.deferredPathPageName = null;
                                return;
                            }

                            const { mesh } = this.paths[i + startPathIndex + 1];
                            if (mesh) {
                                const currentPath = this.paths.find((path) => path.mesh === mesh);
                                const nextPath = this.paths[this.paths.indexOf(currentPath) + 1];

                                this.paths.forEach((path) => {
                                    path.active = false;
                                });

                                if (nextPath) {
                                    nextPath.isAnimating = true;
                                    nextPath.active = true;
                                }

                                this.showCurve(mesh);

                                this.paths.forEach((path) => {
                                    path.active = false;
                                });

                                if (nextPath) {
                                    nextPath.active = true;
                                    nextPath.isAnimating = false;
                                }

                                if (i === endPathIndex - startPathIndex - 1) {
                                    if (currentPath) {
                                        currentPath.active = true;
                                    }

                                    resolve();
                                }
                            } else if (i === endPathIndex - startPathIndex - 1) {
                                // const currentPath = this.paths.find((path) => path.mesh === mesh);

                                // if (currentPath) {
                                //     currentPath.active = true;
                                // }

                                resolve();
                            }
                        }, i * 400);
                        this.timeouts.push(id);
                    }

                    this.showCurve();
                } else {
                    // Hide curves
                    for (let i = 0; i < startPathIndex - endPathIndex; i++) {
                        const id = setTimeout(() => {
                            if (this.deferredPathPageName) {
                                this.animateCurve(this.deferredPathPageName);
                                this.deferredPathPageName = null;
                                return;
                            }

                            const { mesh } = this.paths[startPathIndex - i];
                            if (mesh) {
                                const currentPath = this.paths.find((path) => path.mesh === mesh);
                                const nextPath = this.paths[this.paths.indexOf(currentPath) - 1];

                                this.paths.forEach((path) => {
                                    path.active = false;
                                });

                                if (nextPath) {
                                    nextPath.isAnimating = true;
                                    nextPath.active = true;
                                }

                                this.hideCurve(mesh);

                                if (nextPath) {
                                    nextPath.isAnimating = false;
                                }
                                if (i === startPathIndex - endPathIndex - 1) {
                                    if (currentPath) {
                                        currentPath.active = true;
                                    }

                                    resolve();
                                }
                            } else if (i === startPathIndex - endPathIndex - 1) {
                                // const currentPath = this.paths.find((path) => path.mesh === mesh);

                                // if (currentPath) {
                                //     currentPath.active = true;
                                // }

                                resolve();
                            }
                        }, i * 400);
                        this.timeouts.push(id);
                    }
                }
            } else {
                resolve();
            }
        });
    }

    rotateToOrigin() {
        const absRotationY = this.modelGroup.rotation.y % (2 * Math.PI);
        const y =
            absRotationY < 0
                ? `-=${Math.abs(absRotationY) <= Math.PI ? absRotationY : 2 * Math.PI - Math.abs(absRotationY)}`
                : `+=${Math.abs(absRotationY) <= Math.PI ? -absRotationY : 2 * Math.PI - absRotationY}`;

        TweenLite.to(this.modelGroup.rotation, 1, {
            y,
            ease: Power1.easeInOut,
            onUpdate: () => {
                this.shouldAnimate = true;
            },
            onComplete: () => {
                this.shouldAnimate = false;
            },
        });
    }

    start() {
        this.animate();
    }

    onResize() {
        this.linkOffset = window.matchMedia('(max-width: 1024px)').matches ? 15 : 30;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;
        const mountPos = getMountPos();
        this.modelGroup.position.set(mountPos.x, mountPos.y, mountPos.z);
        this.camera.updateProjectionMatrix();
        this.curveMaterial.linewidth = window.matchMedia('(max-width: 767px)').matches ? 0.5 : 1;
        this.render();
    }

    onVisibilityChange() {
        this.isDocumentHidden = document.visibilityState !== 'visible';
    }

    render() {
        if (this.links) {
            this.links.forEach(({ htmlElement, mesh }) => {
                mesh.updateWorldMatrix(true, false);
                this.tempV.setFromMatrixPosition(mesh.matrixWorld);
                this.tempV.project(this.camera);
                const x = (this.tempV.x * 0.5 + 0.5) * this.width;
                const y = (this.tempV.y * -0.5 + 0.5) * this.height;
                htmlElement.style.transform = `translate3d(0%, -50%, 0) translate3d(${x +
                    this.linkOffset}px, ${y}px, 0)`;
            });
        }

        this.renderer.render(this.scene, this.camera);
    }

    animate() {
        if (this.shouldAnimate && !this.isDocumentHidden) {
            this.render();
        }

        this.rAF = requestAnimationFrame(this.animate);
    }

    // setupGUI() {
    //     // eslint-disable-next-line
    //     const dat = require('dat.gui');
    //     const gui = new dat.GUI();

    //     const lightFolder = gui.addFolder('Directional Light');
    //     lightFolder.add(this.directionalLight.position, 'x', -500, 500, 1);
    //     lightFolder.add(this.directionalLight.position, 'y', -500, 500, 1);
    //     lightFolder.add(this.directionalLight.position, 'z', -500, 500, 1);
    //     lightFolder.add(this.directionalLight, 'intensity', 0, 5, 0.1);

    //     const curvePosFolder = gui.addFolder('Curve Position');
    //     curvePosFolder.add(this.aboutServicesCurve.position, 'x', -500, 500, 0.1);
    //     curvePosFolder.add(this.aboutServicesCurve.position, 'y', -500, 500, 0.1);
    //     curvePosFolder.add(this.aboutServicesCurve.position, 'z', -500, 500, 0.1);

    //     const curveRotationFolder = gui.addFolder('Curve Rotation');
    //     curveRotationFolder.add(this.curve.rotation, 'x', 0, 2 * Math.PI, Math.PI / 32);
    //     curveRotationFolder.add(this.curve.rotation, 'y', 0, 2 * Math.PI, Math.PI / 32);
    //     curveRotationFolder.add(this.curve.rotation, 'z', 0, 2 * Math.PI, Math.PI / 32);

    //     [gui, lightFolder, curvePosFolder, curveRotationFolder].forEach((el) => el.open());
    // }
}
