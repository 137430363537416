import { TweenLite } from 'gsap/all';
import { triggerCustomEvent, listenOnce } from '../utils';

const meteorSizes = [70, 80, 95, 105];

export default class Discount {
    constructor(counterElement) {
        if (!(counterElement instanceof HTMLElement)) {
            throw new Error('[Discount] No DOM element provided.');
        }

        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.min = 0;
        this.max = 10;
        this.current = this.min;
        this.counterElement = counterElement;
        this.counterElement.textContent = this.current;

        this.meteorDiscount = 1;
        this.meteorContainer = document.body;
        this.shouldCreateAnotherMeteor = true;

        this.meteorObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const { target } = entry;
                let { x, y } = this.startingPoint;
                let rotation = 0;
                const initialX = x;
                const mult = 1.75;
                let rAF;
                const isGoingRight = initialX <= this.windowWidth / 2;
                const speed = this.windowWidth <= 900 ? 0.5 : 2;

                const onMeteorDestroy = () => {
                    cancelAnimationFrame(rAF);
                };

                const move = () => {
                    target.style.transform = `translate3d(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
                    x += (isGoingRight ? 1 : -1) * Math.min(speed, 1.2) * mult;
                    y += mult;
                    rotation += isGoingRight ? 0.1 : -0.1;

                    if (entry.isIntersecting) {
                        rAF = requestAnimationFrame(move);
                    } else {
                        cancelAnimationFrame(rAF);
                        this.meteorObserver.unobserve(target);
                        this.destroyMeteor();
                        this.off('meteor-destroy', onMeteorDestroy);
                    }
                };

                move();
                this.one('meteor-destroy', onMeteorDestroy);
            });
        });

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    on(eventName, fn) {
        this.counterElement.addEventListener(eventName, fn);
    }

    one(eventName, fn) {
        listenOnce(this.counterElement, eventName, fn);
    }

    off(eventName, fn) {
        this.counterElement.removeEventListener(eventName, fn);
    }

    trigger(eventName, options) {
        triggerCustomEvent(this.counterElement, eventName, options);
    }

    static animateCounter(element, value) {
        element.dataset.count = value;
        TweenLite.to(element, 1, {
            yPercent: -100,
            onComplete: () => {
                element.textContent = value;
                TweenLite.set(element, { clearProps: 'all' });
            },
        });
    }

    start() {
        this.createMeteor();

        const onMeteorDestroy = () => {
            const delay = 2500;
            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                if (this.shouldCreateAnotherMeteor) {
                    this.createMeteor();
                }
            }, delay);
        };

        this.on('meteor-destroy', onMeteorDestroy);

        this.one('max-reached', () => {
            this.shouldCreateAnotherMeteor = false;
            this.off('meteor-destroy', onMeteorDestroy);
        });
    }

    moveMeteor() {
        this.meteorObserver.observe(this.meteor);
    }

    static generateRandomMeteorSize() {
        return meteorSizes[Math.floor(Math.random() * meteorSizes.length)];
    }

    generateMeteorStartingPoint(size) {
        const coords = {
            x: Math.floor(Math.random() * this.windowWidth),
            y: -size + 1,
        };

        return coords;
    }

    createMeteor() {
        const size = (this.windowWidth <= 1024 ? 0.6 : 1) * Discount.generateRandomMeteorSize();
        this.startingPoint = this.generateMeteorStartingPoint(size);
        this.meteor = document.createElement('button');
        this.meteor.style.width = `${size}px`;
        this.meteor.style.height = `${size}px`;
        this.meteor.className = 'meteor';
        this.meteor.style.transform = `translate3d(${this.startingPoint.x}px, ${this.startingPoint.y}px, 0)`;
        this.meteor.textContent = `${this.meteorDiscount}%`;
        this.meteorContainer.appendChild(this.meteor);
        this.meteor.addEventListener('click', this.increment);
        this.moveMeteor(this.startingPoint);

        return this.meteor;
    }

    destroyMeteor() {
        if (!this.meteor) return;

        this.meteor.removeEventListener('click', this.increment);
        this.trigger('meteor-destroy');

        TweenLite.to(this.meteor, 0.6, {
            scale: 0,
            rotation: 90,
            onComplete: () => {
                this.meteorContainer.removeChild(this.meteor);
                this.meteor = null;
            },
        });
    }

    increment() {
        this.current++;
        this.trigger('change', { value: this.current });
        Discount.animateCounter(this.counterElement, this.current);
        this.destroyMeteor();

        if (this.current >= this.max) {
            this.current = this.max;
            this.trigger('max-reached', { currentDiscountPercent: this.current });
        }
    }

    decrement() {
        this.current--;
        this.counterElement.textContent = this.current;
    }
}
