import Cookies from 'js-cookie';

import { listenOnce } from '../utils';

const ACTIVE_CLASS = 'cookie-banner--visible';
const timeout = 30000;

function createBanner(container) {
    const banner = document.createElement('div');
    banner.className = 'cookie-banner';
    banner.innerHTML = `
        <button class="cookie-banner__close icon js-cookies-accept" aria-label="Закрыть">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11.8284 11.8284" stroke="black" stroke-width="2"/>
                <path d="M12 1L1 12" stroke="black" stroke-width="2"/>
            </svg>
        </button>
        <div class="cookie-banner__text">Продолжая пользоваться сайтом, вы даёте согласие на автоматический сбор и анализ ваших данных, необходимых для работы сайта и его улучшения, использование файлов cookie.</div>
    `;

    if (container instanceof HTMLElement) {
        container.appendChild(banner);
    }

    return banner;
}

function init() {
    if (Cookies.get('COOKIES_ACCEPTED') === 'true') {
        return;
    }

    const banner = createBanner(document.body);
    const acceptBtn = banner.querySelector('.js-cookies-accept');

    setTimeout(() => banner.classList.add(ACTIVE_CLASS), timeout);

    listenOnce(acceptBtn, 'click', () => {
        Cookies.set('COOKIES_ACCEPTED', 'true', { expires: Infinity });
        banner.classList.remove(ACTIVE_CLASS);
        if (banner.parentElement) {
            setTimeout(() => {
                banner.parentElement.removeChild(banner);
            }, 1000);
        }
    });
}

const module = { init };

export default module;
