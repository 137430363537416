/* global ROOT_PATH */
import '../css/app.scss';
import './sentry';
import './polyfills';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import Lazyload from 'vanilla-lazyload';
import { TweenLite, TimelineLite, Power2 } from 'gsap/all';

import './gsap';
import disablePullToRefresh from '../modules/disable-pull-to-refresh';
import Mount from './components/mount';
import Discount from './components/discount';
import cookiesBanner from './components/cookies-banner';
import setCompanyWorkingState from './set-company-working-state';
import { loadFloatedLabels } from './dynamic-modules';
import { isIE } from './utils';
// Transitions
import FromMainToModalTransition from './transitions/main-modal';
import FromMainToOrderingTransition from './transitions/main-ordering';
import FromModalToMainTransition from './transitions/modal-main';
import FromOrderingToMainTransition from './transitions/ordering-main';
import FromModalToModalTransition from './transitions/modal-modal';
import FromOrderingToModalTransition from './transitions/ordering-modal';
import FromModalToOrderingTransition from './transitions/modal-ordering';
// Views
import ModalPageView from './views/modal';
import OrderingPageView from './views/ordering';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');

    const contentElement = document.querySelector('.js-content');
    const mountContainer = document.querySelector('.js-mount-container');
    const preloader = document.querySelector('.js-preloader');
    const navLinks = Array.from(document.querySelectorAll('.js-nav-link'));
    let destroyFloatedLabels;
    let mount;
    const initialAnimationDuration = 1.35;

    const lazyload = new Lazyload({
        elements_selector: '.lazy',
        threshold: 700,
        use_native: true,
    });

    function playInitialAnimation(duration = initialAnimationDuration) {
        return new Promise((resolve) => {
            const tl = new TimelineLite({ onComplete: resolve });

            tl.to('.js-preloader-content', 0.5, {
                opacity: 0,
                onComplete: () => {
                    TweenLite.from('.js-initial-animation', duration, {
                        y: -40,
                        opacity: 0,
                        ease: Power2.easeInOut,
                        delay: 0.3,
                        onComplete: () => {
                            TweenLite.set('.js-initial-animation', { clearProps: 'all' });
                        },
                    });
                },
            }).to(preloader, duration, {
                scaleY: 0,
                ease: Power2.easeInOut,
                delay: 0.3,
            });
        });
    }

    disablePullToRefresh();
    setCompanyWorkingState();

    const discountCounter = document.querySelector('.js-discount-amount');
    let discount;

    if (discountCounter) {
        discount = new Discount(discountCounter);
        window.discount = discount;

        discount.one('max-reached', () => {
            const currentPageName = document.querySelector('[data-barba-namespace][data-name]').dataset.name;

            if (currentPageName !== 'ordering') {
                barba.go(`${ROOT_PATH}ordering/`);
            }
        });
    }

    if (mountContainer && !isIE()) {
        const onError = () => {
            playInitialAnimation().then(() => {
                document.body.removeChild(preloader);

                if (discount) {
                    setTimeout(() => discount.start(), 300);
                }
            });
        };

        try {
            mount = new Mount(mountContainer, {
                onReady: () => {
                    if (preloader) {
                        playInitialAnimation().then(() => {
                            document.body.removeChild(preloader);

                            if (discount) {
                                setTimeout(() => discount.start(), 300);
                            }
                        });

                        TweenLite.from(mount.camera.position, initialAnimationDuration, {
                            y: -40,
                            z: '+=40',
                            ease: Power2.easeInOut,
                            delay: 0.8,
                            onUpdate: () => {
                                mount.shouldAnimate = true;
                            },
                            onComplete: () => {
                                mount.shouldAnimate = false;
                            },
                        });
                    }

                    cookiesBanner.init();
                },
                onError,
            });
        } catch (err) {
            onError();
            console.error(err);
        }
    } else {
        // IE
        document.body.removeChild(preloader);
        cookiesBanner.init();
    }

    const current = {
        container: document.querySelector('[data-barba-namespace]'),
        namespace: document.querySelector('[data-barba-namespace]').dataset.barbaNamespace,
    };

    if (current.namespace !== 'index-page' && contentElement) {
        contentElement.classList.add('active');

        if (mount && !document.documentElement.classList.contains('no-webgl')) {
            if (current.namespace !== 'ordering-page' && window.matchMedia('(min-width: 1025px)').matches) {
                mount.group.position.x = -160;
            }

            setTimeout(() => {
                mount.animateCurve(current.container.dataset.name);
            }, 300);
        }
    }

    barba.hooks.beforeEnter(({ next }) => {
        const floatedLabelsElements = Array.from(document.querySelectorAll('.floated-labels'));

        if (floatedLabelsElements.length > 0) {
            loadFloatedLabels().then((floatedLabels) => {
                destroyFloatedLabels = floatedLabels();
            });
        }

        if (lazyload instanceof Lazyload) {
            lazyload.update();
        }

        const activeLink = navLinks.find((link) => link.href === next.url.href);

        if (activeLink) {
            activeLink.classList.add('disabled');
        }
    });

    barba.hooks.beforeLeave(() => {
        if (typeof destroyFloatedLabels === 'function') {
            destroyFloatedLabels();
        }

        navLinks.forEach((link) => link.classList.remove('disabled'));
    });

    barba.use(barbaPrefetch);

    barba.init({
        transitions: [
            FromMainToModalTransition,
            FromModalToMainTransition,
            FromModalToModalTransition,
            FromMainToOrderingTransition,
            FromOrderingToMainTransition,
            FromOrderingToModalTransition,
            FromModalToOrderingTransition,
        ],
        views: [ModalPageView, OrderingPageView],
        preventRunning: true,
    });
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
    });
}
