import { TweenLite } from 'gsap/all';
import { triggerCustomEvent } from '../utils';

export default {
    from: {
        namespace: ['ordering-page'],
    },
    to: {
        namespace: ['modal-page'],
    },

    beforeAppear() {
        //
    },

    appear() {
        //
    },

    afterAppear() {
        //
    },

    beforeLeave() {
        //
    },

    beforeEnter() {
        //
    },

    leave({ current }) {
        return new Promise(resolve => {
            TweenLite.to(current.container, 0.25, {
                opacity: 0,
                onComplete: resolve,
            });
        });
    },

    async enter({ current, next }) {
        TweenLite.fromTo(next.container, 1, { yPercent: 100 }, { yPercent: 0, delay: 0.07 });

        triggerCustomEvent(window, 'page-transition-switch', {
            currentPageName: current.container.dataset.name,
            nextPageName: next.container.dataset.name,
        });
    },

    afterLeave() {
        //
    },

    afterEnter() {
        //
    },
};
