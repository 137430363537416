import { TweenLite } from 'gsap/all';

import { triggerCustomEvent, loadAssets, listenOnce } from '../utils';

const contentElement = document.querySelector('.js-content');
const stopPropagation = (event) => event.stopPropagation();

export default {
    from: 'index-page',
    to: {
        namespace: ['ordering-page'],
    },

    beforeAppear() {
        //
    },

    appear() {
        //
    },

    afterAppear() {
        //
    },

    beforeLeave() {
        contentElement.removeEventListener('mousedown', stopPropagation);
        contentElement.removeEventListener('mouseup', stopPropagation);
    },

    beforeEnter() {
        //
    },

    leave() {
        //
    },

    enter({ current, next }) {
        return new Promise((resolve) => {
            loadAssets(next.container).then(() => {
                listenOnce(window, 'path-animate-complete', () => {
                    if (contentElement) {
                        contentElement.classList.add('active');
                    }

                    TweenLite.fromTo(
                        next.container,
                        0.5,
                        { autoAlpha: 0 },
                        {
                            autoAlpha: 1,
                            delay: 0.1,
                            onComplete: () => {
                                TweenLite.set(next.container, { clearProps: 'all' });
                            },
                        },
                    );

                    resolve();
                });

                triggerCustomEvent(window, 'page-transition-enter', {
                    currentPageName: current.container.dataset.name,
                    nextPageName: next.container.dataset.name,
                });
            });

            document.body.classList.add('modal-page-opened');
        });
    },

    afterLeave() {
        //
    },

    afterEnter() {
        //
    },
};
