// import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera';
// import { PropertyBinding } from 'three/src/animation/PropertyBinding';
// import { Object3D } from 'three/src/core/Object3D';
// import { Geometry } from 'three/src/core/Geometry';
// import { BufferGeometry } from 'three/src/core/BufferGeometry';
// import { BufferAttribute } from 'three/src/core/BufferAttribute';
// import { InterleavedBufferAttribute } from 'three/src/core/InterleavedBufferAttribute';
// import { InterleavedBuffer } from 'three/src/core/InterleavedBuffer';
// import { _Math as Math } from 'three/src/math/Math';
// import { Vector2 } from 'three/src/math/Vector2';
// import { Vector3 } from 'three/src/math/Vector3';
// import { Matrix4 } from 'three/src/math/Matrix4';
// import { Spherical } from 'three/src/math/Spherical';
// import { CubicBezierCurve3 } from 'three/src/extras/curves/CubicBezierCurve3';
// // import { Face3 } from 'three/src/core/Face3';
// // import { UniformsUtils } from 'three/src/renderers/shaders/UniformsUtils';
// // import { OrthographicCamera } from 'three/src/cameras/OrthographicCamera';
// import { Scene } from 'three/src/scenes/Scene';
// // import { BoxBufferGeometry } from 'three/src/geometries/BoxGeometry';
// // import { PlaneBufferGeometry } from 'three/src/geometries/PlaneGeometry';
// import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial';
// import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
// import { SpriteMaterial } from 'three/src/materials/SpriteMaterial';
// import { ShaderMaterial } from 'three/src/materials/ShaderMaterial';
// import { PointsMaterial } from 'three/src/materials/PointsMaterial';
// import { LineBasicMaterial } from 'three/src/materials/LineBasicMaterial';
// import { Mesh } from 'three/src/objects/Mesh';
// import { SkinnedMesh } from 'three/src/objects/SkinnedMesh';
// import { Sprite } from 'three/src/objects/Sprite';
// import { Group } from 'three/src/objects/Group';
// import { LineSegments } from 'three/src/objects/LineSegments';
// import { Line } from 'three/src/objects/Line';
// import { LineLoop } from 'three/src/objects/LineLoop';
// import { Points } from 'three/src/objects/Points';
// // import {
// //     LinearFilter,
// //     // NearestFilter,
// //     // BackSide,
// //     DoubleSide,
// //     // LinearToneMapping,
// //     CubeUVReflectionMapping,
// //     sRGBEncoding,
// //     // GammaEncoding,
// //     // LinearEncoding,
// //     // RGBEEncoding,
// //     // RGBM16Encoding,
// //     // NoBlending,
// // } from 'three/src/constants';

// // import { Clock } from 'three/src/core/Clock';
// import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer';
// import { ShaderLib } from 'three/src/renderers/shaders/ShaderLib';
// import { UniformsUtils } from 'three/src/renderers/shaders/UniformsUtils';
// // import { DirectionalLight } from 'three/src/lights/DirectionalLight';
// import { AmbientLight } from 'three/src/lights/AmbientLight';
// import { DirectionalLight } from 'three/src/lights/DirectionalLight';
// // import { IcosahedronBufferGeometry } from 'three/src/geometries/IcosahedronGeometry';
// // import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';
// import { Color } from 'three/src/math/Color';
// import { Interpolant } from 'three/src/math/Interpolant';
// import { Loader } from 'three/src/loaders/Loader';
// import { TextureLoader } from 'three/src/loaders/TextureLoader';
// // import { ImageLoader } from 'three/src/loaders/ImageLoader';
// // import { ImageBitmapLoader } from 'three/src/loaders/ImageBitmapLoader';
// import { DefaultLoadingManager } from 'three/src/loaders/LoadingManager';
// import { FileLoader } from 'three/src/loaders/FileLoader';
// import { LoaderUtils } from 'three/src/loaders/LoaderUtils';
// // import { Texture } from 'three/src/textures/Texture';
// import { CanvasTexture } from 'three/src/textures/CanvasTexture';
// // import { CubeTexture } from 'three/src/textures/CubeTexture';
// import {
//     NearestFilter,
//     LinearFilter,
//     NearestMipmapNearestFilter,
//     LinearMipmapNearestFilter,
//     NearestMipmapLinearFilter,
//     LinearMipmapLinearFilter,
//     ClampToEdgeWrapping,
//     RepeatWrapping,
//     MirroredRepeatWrapping,
//     InterpolateLinear,
//     InterpolateDiscrete,
//     RGBFormat,
//     RGBAFormat,
//     sRGBEncoding,
//     FrontSide,
//     DoubleSide,
//     VertexColors,
//     TriangleStripDrawMode,
//     TriangleFanDrawMode,
// } from 'three/src/constants';

// const THREE = {
//     PerspectiveCamera,
//     PropertyBinding,
//     Geometry,
//     Vector2,
//     Vector3,
//     Scene,
//     WebGLRenderer,
//     Color,
//     Math,
//     AmbientLight,
//     DirectionalLight,
//     Group,
//     CubicBezierCurve3,
//     InterleavedBuffer,
//     MeshBasicMaterial,
//     MeshStandardMaterial,
//     ShaderMaterial,
//     PointsMaterial,
//     LineBasicMaterial,
//     CanvasTexture,
//     Object3D,
//     Mesh,
//     SkinnedMesh,
//     LineSegments,
//     Line,
//     LineLoop,
//     Points,
//     Matrix4,
//     Spherical,
//     SpriteMaterial,
//     Sprite,
//     TextureLoader,
//     DefaultLoadingManager,
//     FileLoader,
//     BufferGeometry,
//     BufferAttribute,
//     InterleavedBufferAttribute,
//     Loader,
//     LoaderUtils,
//     ShaderLib,
//     UniformsUtils,
//     Interpolant,
//     NearestFilter,
//     LinearFilter,
//     NearestMipmapNearestFilter,
//     LinearMipmapNearestFilter,
//     NearestMipmapLinearFilter,
//     LinearMipmapLinearFilter,
//     ClampToEdgeWrapping,
//     RepeatWrapping,
//     MirroredRepeatWrapping,
//     InterpolateLinear,
//     InterpolateDiscrete,
//     RGBFormat,
//     RGBAFormat,
//     FrontSide,
//     DoubleSide,
//     VertexColors,
//     sRGBEncoding,
//     TriangleStripDrawMode,
//     TriangleFanDrawMode,
// };

import * as THREE from 'three';

window.THREE = THREE;

require('three/examples/js/loaders/GLTFLoader');
require('three/examples/js/loaders/DRACOLoader');

export default THREE;
