import { TweenLite, Power2 } from 'gsap/all';
import clamp from 'clamp';
import { fromEvent } from 'rxjs';
import { merge, map, mergeMap, takeWhile, takeUntil, tap } from 'rxjs/operators';

import { findParent } from '../../../utils';

export default (instance) => {
    if (document.documentElement.classList.contains('no-webgl')) {
        return () => {};
    }

    let canceled = false;
    let tween;
    let prevClientX = 0;
    let velocity = 0;
    let rafId;

    const mouseEventToCoordinate = (event) => ({
        event,
        x: event.clientX,
        y: event.clientY,
    });

    const touchEventToCoordinate = (event) => ({
        event,
        x: event.changedTouches[0].clientX * 4,
        y: event.changedTouches[0].clientY * 4,
    });

    const decreaseVelocity = () => {
        velocity *= 0.4;
        rafId = requestAnimationFrame(decreaseVelocity);
    };

    const setInitialState = ({ x }) => {
        if (tween) tween.kill();
        canceled = false;
        prevClientX = x;
        velocity = 0;
        rafId = requestAnimationFrame(decreaseVelocity);
    };

    const rotateMount = ({ x }) => {
        if (!instance.model) return;

        const direction = x > prevClientX ? 'right' : 'left';
        const koef = direction === 'right' ? 1 : -1;
        const diff = x - prevClientX;
        velocity = clamp(x - prevClientX, -6, 6);
        prevClientX = x;

        const deltaY = koef * ((diff > 0 ? 1 : -1) * diff) * 0.001;

        instance.modelGroup.rotation.y += deltaY;
        instance.starsGroup.rotation.y += deltaY;
    };

    const smoothlyStopMount = () => {
        if (!instance.model || canceled) return;

        cancelAnimationFrame(rafId);

        tween = TweenLite.to([instance.modelGroup.rotation, instance.starsGroup.rotation], 0.8, {
            y: `+=${velocity * 0.1}`,
            ease: Power2.easeOut,
            onUpdate: () => {
                instance.shouldAnimate = true;
            },
            onComplete: () => {
                // setTimeout(() => {
                instance.shouldAnimate = false;
                // }, 1000);
            },
        });
    };

    const mousedown$ = fromEvent(document, 'mousedown').pipe(map(mouseEventToCoordinate));
    const mousemove$ = fromEvent(document, 'mousemove').pipe(map(mouseEventToCoordinate));
    const mouseup$ = fromEvent(document, 'mouseup').pipe(map(mouseEventToCoordinate));

    const touchstart$ = fromEvent(document, 'touchstart').pipe(map(touchEventToCoordinate));
    const touchmove$ = fromEvent(document, 'touchmove').pipe(map(touchEventToCoordinate));
    const touchend$ = fromEvent(document, 'touchend').pipe(map(touchEventToCoordinate));

    const down$ = mousedown$.pipe(merge(touchstart$));
    const move$ = mousemove$.pipe(merge(touchmove$));
    const up$ = mouseup$.pipe(merge(touchend$));

    const rotation$ = down$.pipe(
        tap(setInitialState),
        tap(() => {
            instance.shouldAnimate = true;
        }),
        mergeMap(() =>
            move$.pipe(
                tap(({ event }) => event.preventDefault()),
                tap(() => {
                    instance.shouldAnimate = true;
                }),
                takeWhile(({ event }) => {
                    if (!event.target) return false;
                    const contentElement = findParent('.js-content', event.target);
                    const shouldBeInterrupted = !!contentElement;

                    if (shouldBeInterrupted) {
                        smoothlyStopMount();
                        canceled = true;
                    }

                    return !shouldBeInterrupted;
                }),
                takeUntil(up$),
            ),
        ),
    );

    const upSubscription = up$.subscribe(smoothlyStopMount);
    const rotationSubscription = rotation$.subscribe(rotateMount);

    return () => {
        cancelAnimationFrame(rafId);
        rotationSubscription.unsubscribe();
        upSubscription.unsubscribe();
    };
};
