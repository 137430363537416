/* global ROOT_PATH */
import barba from '@barba/core';
import overlayScrollbars from 'overlayscrollbars';

// import useOFIPolyfill from '../use-object-fit-images';
import { loadInputmask } from '../dynamic-modules';
import initCollapses from '../components/collapse';
import { KEYCODES } from '../utils';

const onKeydown = (event) => {
    if (event.keyCode === KEYCODES.ESC) {
        event.preventDefault();
        barba.go(ROOT_PATH);
    }
};

const imgObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            const { target } = entry;
            if (entry.isIntersecting) {
                target.classList.add('content-visible');
                imgObserver.unobserve(target);
            }
        });
    },
    { threshold: [0.2, 0.8] },
);

const videoObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const { target } = entry;
        if (entry.isIntersecting) {
            target.play().catch((err) => console.error(err));
        } else {
            target.pause();
        }
    });
});

let destroyCollapse;

export default {
    namespace: 'modal-page',

    beforeEnter({ next }) {
        overlayScrollbars(document.querySelectorAll('.js-overlayscrollbars'), {});
        // useOFIPolyfill(next.container.querySelectorAll('.img-cover'));
        document.addEventListener('keydown', onKeydown);

        const phonesToMask = Array.from(document.querySelectorAll('.js-mask--phone'));

        if (phonesToMask.length > 0) {
            loadInputmask()
                .then((Inputmask) => {
                    Inputmask({
                        mask: '+7 (999) 999-99-99',
                        showMaskOnHover: false,
                    }).mask(phonesToMask);
                })
                .catch((err) => {
                    throw err;
                });
        }

        Array.from(next.container.querySelectorAll('.js-content-observe')).forEach((img) => imgObserver.observe(img));
        destroyCollapse = initCollapses();

        Array.from(document.querySelectorAll('video')).forEach((video) => {
            videoObserver.observe(video);
        });
    },

    afterEnter() {
        //
    },

    beforeLeave() {
        document.removeEventListener('keydown', onKeydown);
        imgObserver.disconnect();
        videoObserver.disconnect();

        if (destroyCollapse) {
            destroyCollapse();
        }
    },

    afterLeave() {
        //
    },
};
