import { triggerCustomEvent } from '../utils';
import { TweenLite } from 'gsap/all';

const contentElement = document.querySelector('.js-content');

export default {
    from: {
        namespace: ['ordering-page'],
    },
    to: {
        namespace: ['index-page'],
    },

    beforeAppear() {
        //
    },

    appear() {
        //
    },

    afterAppear() {
        //
    },

    beforeLeave() {
        //
    },

    beforeEnter() {
        //
    },

    leave({ current, next }) {
        return new Promise((resolve) => {
            TweenLite.to(current.container, 0.3, {
                opacity: 0,
                onComplete: () => {
                    contentElement.classList.remove('active');
                    resolve();
                },
            });

            triggerCustomEvent(window, 'page-transition-leave', {
                currentPageName: current.container.dataset.name,
                nextPageName: next.container.dataset.name,
            });

            document.body.classList.remove('modal-page-opened');
        });
    },

    enter() {
        //
    },

    afterLeave() {
        //
    },

    afterEnter() {
        //
    },
};
