export const loadInputmask = () =>
    import(/* webpackChunkName: "inputmask" */ 'inputmask')
        .then((module) => module.default)
        .catch((err) => {
            throw err.message || err;
        });

export const loadAjaxFormSender = () =>
    import(/* webpackChunkName: "ajax-form-sender" */ '../modules/ajax-form-sender')
        .then((module) => module.default)
        .catch((err) => {
            throw err.message || err;
        });

export const loadValidator = () =>
    import(/* webpackChunkName: "validator" */ '../modules/validator')
        .then((module) => module.default)
        .catch((err) => {
            throw err.message || err;
        });

export const loadFloatedLabels = () =>
    import(/* webpackChunkName: "floated-labels" */ './components/floated-labels')
        .then((module) => module.default)
        .catch((err) => {
            throw new Error(`Failed to load "floated-labels chunk": ${err}`);
        });

// export const loadAndApplyOFIPolyfill = elements => import(
//     /* webpackChunkName: "object-fit-images" */
//     'object-fit-images')
//     .then(module => module.default)
//     .then(objectFitImages => objectFitImages(elements))
//     .catch((err) => {
//         throw new Error(err);
//     });
