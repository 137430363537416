import { findParent } from '../utils';

const fnMap = {
    toggle: 'toggle',
    show: 'add',
    hide: 'remove',
};

export const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach((target) => {
        target.classList[fnMap[cmd]]('show');

        const togglerTextEls = Array.from(
            document.querySelectorAll(`[data-toggle="collapse"][data-target="${selector}"] .js-collapse-btn-text`),
        );
        togglerTextEls.forEach((el) => {
            el.textContent = target.classList.contains('show') ? 'Скрыть' : 'Подробнее';
        });
    });
};

export default () => {
    // const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));

    const onClick = (event) => {
        // const el = event.target;
        const el = findParent('[data-toggle="collapse"]', event.target);
        // if (triggers.includes(el)) {
        if (el) {
            const selector = el.getAttribute('data-target');
            collapse(selector, 'toggle');
        }
    };

    document.addEventListener('click', onClick);

    return () => {
        document.removeEventListener('click', onClick);
    };
};
