import { TweenLite } from 'gsap/all';
import { triggerCustomEvent, timeout } from '../utils';

export default {
    from: {
        namespace: ['modal-page'],
    },
    to: {
        namespace: ['modal-page'],
    },
    sync: true,

    beforeAppear() {
        //
    },

    appear() {
        //
    },

    afterAppear() {
        //
    },

    beforeLeave() {
        //
    },

    beforeEnter() {
        //
    },

    async leave({ current, next }) {
        if (current.url.href === next.url.href) {
            return;
        }

        TweenLite.to(current.container, 0.8, { z: -200, yPercent: 10 });
        await timeout(1000);
    },

    async enter({ current, next }) {
        if (current.url.href === next.url.href) {
            return;
        }

        TweenLite.fromTo(
            next.container,
            1,
            { yPercent: 100 },
            {
                yPercent: 0,
                delay: 0.2,
            },
        );

        triggerCustomEvent(window, 'page-transition-switch', {
            currentPageName: current.container.dataset.name,
            nextPageName: next.container.dataset.name,
        });
    },

    afterLeave() {
        //
    },

    afterEnter() {
        //
    },
};
