import 'gsap/EasePack';
import {
    CSSPlugin,
    // AttrPlugin,
    // ModifiersPlugin,
    // ScrollToPlugin,
    // DirectionalRotationPlugin,
} from 'gsap/all';

const plugins = [
    CSSPlugin,
    // AttrPlugin,
    // ModifiersPlugin,
    // ScrollToPlugin,
    // DirectionalRotationPlugin,
];

// Нужно, чтобы плагины не тришейкились
if (false) {
    console.log(plugins);
}
