import { TweenLite, Power1 } from 'gsap/all';

import { timeout, triggerCustomEvent } from '../../../utils';

export default (instance) => {
    let isDesktop = window.matchMedia('(min-width: 1025px)').matches;

    const moveMount = (x) =>
        TweenLite.to(instance.group.position, 1, {
            x,
            ease: Power1.easeInOut,
            onUpdate: () => {
                instance.shouldAnimate = true;
            },
            onComplete: () => {
                instance.shouldAnimate = false;
            },
        });

    const onResize = () => {
        isDesktop = window.matchMedia('(min-width: 1025px)').matches;
    };

    const onEnter = async ({ detail: { nextPageName } }) => {
        if (instance.model && !document.documentElement.classList.contains('no-webgl')) {
            instance.isModalPageActive = true;
            instance.rotateToOrigin();

            if (isDesktop && nextPageName !== 'ordering') {
                moveMount(-160);
            }

            if (isDesktop) {
                instance.animateCurve(nextPageName, true);
            } else {
                await instance.animateCurve(nextPageName, true);
            }
        }

        triggerCustomEvent(window, 'path-animate-complete');
    };

    const onSwitch = ({ detail: { nextPageName } }) => {
        if (!document.documentElement.classList.contains('no-webgl')) {
            instance.rotateToOrigin();
            instance.animateCurve(nextPageName);
        }
    };

    const onLeave = async ({ detail: { nextPageName } }) => {
        if (!instance.model || document.documentElement.classList.contains('no-webgl')) return;
        instance.isModalPageActive = false;

        if (isDesktop) {
            moveMount(0);
        } else {
            await timeout(700);
        }

        instance.shouldAnimate = true;
        instance.animateCurve(nextPageName);
    };

    window.addEventListener('resize', onResize);
    window.addEventListener('page-transition-enter', onEnter);
    window.addEventListener('page-transition-leave', onLeave);
    window.addEventListener('page-transition-switch', onSwitch);

    return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('page-transition-enter', onEnter);
        window.removeEventListener('page-transition-leave', onLeave);
        window.removeEventListener('page-transition-switch', onSwitch);
    };
};
