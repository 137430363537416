export default function loadAssets(element = document) {
    return new Promise((resolve) => {
        const images = Array.from(element.querySelectorAll('img'));
        let loadedCount = 0;

        const complete = () => {
            loadedCount++;

            if (loadedCount === images.length) {
                resolve();
            }
        };

        if (images.length === 0) {
            resolve();
            return;
        }

        images.forEach((img) => {
            const _img = new Image();
            _img.src = img.src;
            _img.onload = complete;
            _img.onerror = complete;
        });
    });
}
