/* global ROOT_PATH yaCounter55913515 */
import barba from '@barba/core';
import overlayScrollbars from 'overlayscrollbars';

import { loadInputmask, loadAjaxFormSender, loadValidator } from '../dynamic-modules';
import Discount from '../components/discount';
import { KEYCODES, timeout } from '../utils';

const onKeydown = (event) => {
    if (event.keyCode === KEYCODES.ESC) {
        event.preventDefault();
        barba.go(ROOT_PATH);
    }
};

const initForm = (form, { Validator, AjaxFormSender }) => {
    const validator = new Validator(form);
    const { inputs } = validator;

    function onInputFocus() {
        this.classList.remove('is-error');
    }

    const onSubmit = (event) => {
        event.preventDefault();
        const isFormValid = validator.validate();

        if (isFormValid) {
            const onError = () => {
                const failureMessage = document.querySelector('.js-form-message__failure');
                if (failureMessage) {
                    failureMessage.classList.remove('form-message--hidden');
                }
            };

            const sender = new AjaxFormSender(form, {
                onBeforeSend: () => {
                    const checkInput = form.querySelector('input.js-check-val');

                    if (checkInput) {
                        checkInput.value = '';
                    }
                },
                onSend: () => {
                    const discountEl = document.querySelector('.js-ordering-discount');

                    if (discountEl) {
                        const discountPercent = parseFloat(discountEl.textContent.trim());
                        sender.appendData('discount-percent', discountPercent);
                    }
                },
                onSuccess: ({ success }) => {
                    if (success) {
                        const successMessage = document.querySelector('.js-form-message__success');

                        if (successMessage) {
                            successMessage.classList.remove('form-message--hidden');
                        }

                        if (yaCounter55913515) {
                            yaCounter55913515.reachGoal('formSubmit');
                        }
                    } else {
                        onError();
                    }
                },
                onError,
                onComplete: async () => {
                    const messagesContainer = document.querySelector('.js-form-messages');

                    if (messagesContainer) {
                        messagesContainer.classList.remove('form-messages--hidden');
                    }

                    await timeout(5000);
                    barba.go(ROOT_PATH);
                },
            });

            sender.send().catch((err) => {
                throw err;
            });
        }
    };

    form.setAttribute('novalidate', true);
    form.addEventListener('submit', onSubmit);
    inputs.forEach((input) => {
        input.addEventListener('focus', onInputFocus);
    });

    return () => {
        form.removeEventListener('submit', onSubmit);
        inputs.forEach((input) => {
            input.removeEventListener('focus', onInputFocus);
        });
    };
};

const initForms = (forms, deps) => {
    const destroyFunctions = forms.map((form) => initForm(form, deps));
    return () => destroyFunctions.forEach((fn) => fn());
};

const contentElement = document.querySelector('.js-content');
let destroyForms;

export default {
    namespace: 'ordering-page',

    beforeEnter({ next }) {
        const forms = Array.from(next.container.querySelectorAll('.js-ajax-form'));

        overlayScrollbars(document.querySelectorAll('.js-overlayscrollbars'), {});

        document.addEventListener('keydown', onKeydown);
        document.body.classList.add('ordering-page');

        if (forms.length > 0) {
            Promise.all([loadValidator(), loadAjaxFormSender()])
                .then(([Validator, AjaxFormSender]) => {
                    destroyForms = initForms(forms, { Validator, AjaxFormSender });
                })
                .catch((err) => {
                    throw err;
                });
        }

        const phonesToMask = Array.from(document.querySelectorAll('.js-mask--phone'));

        if (phonesToMask.length > 0) {
            loadInputmask()
                .then((Inputmask) => {
                    Inputmask({
                        mask: '+7 (999) 999-99-99',
                        showMaskOnHover: false,
                    }).mask(phonesToMask);
                })
                .catch((err) => {
                    throw err;
                });
        }

        if (contentElement) {
            contentElement.classList.remove('content-wrapper');
            contentElement.classList.add('ordering-content-wrapper');
        }

        const { discount } = window;
        this.formDiscountElement = next.container.querySelector('.js-ordering-discount');
        this.onDiscountChange = ({ detail: { value } }) => Discount.animateCounter(this.formDiscountElement, value);

        if (this.formDiscountElement && discount) {
            this.formDiscountElement.textContent = discount.current;
            discount.on('change', this.onDiscountChange);
        }
    },

    afterEnter() {
        //
    },

    beforeLeave() {
        const { discount } = window;

        document.removeEventListener('keydown', onKeydown);
        document.body.classList.remove('ordering-page');

        if (destroyForms) {
            destroyForms();
            destroyForms = null;
        }

        if (discount) {
            discount.off('change', this.onDiscountChange);
        }

        this.onDiscountChange = null;
        this.formDiscountElement = null;
    },

    afterLeave() {
        if (contentElement) {
            contentElement.classList.remove('ordering-content-wrapper');
            contentElement.classList.add('content-wrapper');
        }
    },
};
