import { TweenLite } from 'gsap/all';
import { triggerCustomEvent, loadAssets, listenOnce } from '../utils';

const contentElement = document.querySelector('.js-content');
const stopPropagation = (event) => event.stopPropagation();

export default {
    from: 'index-page',
    to: {
        namespace: ['modal-page'],
    },

    beforeAppear() {
        //
    },

    appear() {
        //
    },

    afterAppear() {
        //
    },

    beforeLeave() {
        contentElement.removeEventListener('mousedown', stopPropagation);
        contentElement.removeEventListener('mouseup', stopPropagation);
    },

    beforeEnter() {
        //
    },

    leave() {
        //
    },

    enter({ current, next }) {
        return new Promise((resolve) => {
            loadAssets(next.container).then(() => {
                listenOnce(window, 'path-animate-complete', () => {
                    if (contentElement) {
                        contentElement.classList.add('active');
                        contentElement.addEventListener('mousedown', stopPropagation);
                        contentElement.addEventListener('mouseup', stopPropagation);
                    }

                    TweenLite.fromTo(next.container, 1, { yPercent: 100 }, { yPercent: 0, delay: 0.1 });
                    resolve();
                });

                triggerCustomEvent(window, 'page-transition-enter', {
                    currentPageName: current.container.dataset.name,
                    nextPageName: next.container.dataset.name,
                });
            });

            document.body.classList.add('modal-page-opened');
        });
    },

    afterLeave() {
        //
    },

    afterEnter() {
        //
    },
};
