import { TweenLite } from 'gsap/all';
import { triggerCustomEvent } from '../utils';

export default {
    from: {
        namespace: ['modal-page'],
    },
    to: {
        namespace: ['ordering-page'],
    },

    beforeAppear() {
        //
    },

    appear() {
        //
    },

    afterAppear() {
        //
    },

    beforeLeave() {
        //
    },

    beforeEnter() {
        //
    },

    leave({ current }) {
        return new Promise(resolve => {
            TweenLite.to(current.container, 0.9, {
                yPercent: 100,
                onComplete: resolve,
            });
        });
    },

    enter({ current, next }) {
        TweenLite.fromTo(next.container, 0.5, { opacity: 0 }, { opacity: 1 });

        triggerCustomEvent(window, 'page-transition-switch', {
            currentPageName: current.container.dataset.name,
            nextPageName: next.container.dataset.name,
        });
    },

    afterLeave() {
        //
    },

    afterEnter() {
        //
    },
};
